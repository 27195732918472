$fonts-root: '../fonts/';

// http://www.font2web.com/
@mixin add-font ($name, $path, $style, $weight) {
	@font-face {
		font-family: $name;
		src: url($fonts-root + $path + ".eot");
		src: url($fonts-root + $path + ".eot?#iefix")format("embedded-opentype"), url($fonts-root + $path + ".woff") format("woff"), url($fonts-root + $path + ".ttf") format("truetype");
		font-style: $style;
		font-weight: $weight; } }


// @include add-font ("fontName", "fontCatalog/fileName", normal, normal )

@include add-font ("Roboto", "roboto/Roboto-Medium", normal, 500 );
@include add-font ("Segoe UI", "SegoeUI/SegoeUIBold", normal, 700 );
@include add-font ("Segoe UI", "SegoeUI/SegoeUIRegular", normal, 400 );
@include add-font ("Segoe UI", "SegoeUI/SegoeUILight", normal, 300 );
