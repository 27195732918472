@import "_mixins";

header {
    position: relative;
    z-index: 3;
    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding-top: 22px;
        padding-bottom: 22px; }
    .logo {
        height: 62px;
        width: 51px; }
    .menu {
        display: flex;
        justify-content: space-between;
        flex-grow: 1; }
    nav {
        flex-grow: 1;
        padding: 0 24px; }
    .list_nav {
        max-width: 660px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap; }
    .item_nav {
        margin: 0 6px;
        cursor: pointer;
        padding: 4px 0;
        a {
            color: $main-color;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            text-transform: uppercase;
            letter-spacing: 2.8px;
            display: inline-block;
            position: relative;
            &:before {
                content: "";
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 4px;
                background-color: inherit;
                transition: 0.25s; } }
        &.active {
            a {
                &:before {
                    background-color: $color2;
                    transition: 0.25s; } } }

        &:hover {
            a {
                &:before {
                    background-color: $color2;
                    transition: 0.25s; } } } }
    .social {
        li:not(:last-child) {
            margin-right: 7px; } }

    @media (max-width: 800px) {
        .container {
            padding: 6px 20px; }
        .menu {
            position: absolute;
            z-index: 99;
            top: -120px;
            bottom: 120px;
            background-color: #402104;
            left: 0;
            right: 0;
            padding-right: 70px;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;
            align-content: center;
            .list_nav {
                justify-content: center; }
            .item_nav {
                &:before {
                    bottom: 2px; } } } }
    @media (max-width: 680px) {
        .menu {
            top: -200px;
            padding: 20px;
            .item_nav {
                width: 100%;
                text-align: center; } } } }

.home {
    header {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        //.container
        //    padding-left: 0
 } }        //    padding-right: 0
.wrapper_wh {
    header {
        .menu {
            background-color: #ffffff; }
        a {
            color: $color3; } }
    @media (max-width: 680px) {
        header {
            .menu {
                padding: 20px; } } } }

