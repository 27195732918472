@import "_mixins";

.block_about_us {
    background-color: #f5e2d0;
    color: $color5;
    padding-top: 68px;
    padding-bottom: 50px;
    h2 {
        text-align: center; }
    .foto_about, .block_text {
        max-width: 820px;
        margin: auto;
        margin-bottom: 48px;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: 0.8px; } }
