@import "_mixins";

footer {
    background-color: #402104;
    .container {
        padding-top: 25px;
        padding-bottom: 25px;
        text-align: center;
        position: relative; }
    .copyright {
        font-size: 14px;
        &:first-child {
            padding-bottom: 2px; }
        a {
            font-size: 15px;
            &:hover {
                color: $color2; } } }
    .social {
        position: absolute;
        right: 20px;
        top: 30px;
        li:not(:last-child) {
            margin-right: 14px; } }
    @media (max-width: 420px) {
        .social {
            position: relative;
            top: auto;
            right: auto;
            margin-top: 10px; } } }
