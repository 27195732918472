@import "_mixins";

.block_product_card {
    color: $color3;
    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding-top: 26px;
        margin-bottom: 125px;
        > * {
            width: 50%; } }
    h3 {
        text-align: center; }
    .product_deck {
        padding-left: 104px;
        .block_text {
            margin-bottom: 46px;
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;
            letter-spacing: 0.8px; }
        .btn {
            background-color: $color4;
            float: right; } }
    .product_gallery {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;
        height: 400px;
        width: 50%; }
    #product_gallery {
        width: 90px;
        margin-right: 4px;
        padding-right: 2px;
        overflow: auto;
        &::-webkit-scrollbar {
            width: 6px; }
        &::-webkit-scrollbar-track {
            background: #f8f7f7;
            border: 1px solid #efeeee; }
        &::-webkit-scrollbar-thumb {
            background-color: $main-color-hover;
            border: 2px solid #efeeee; }
        .image-holder {
            display: inline-block;
            height: 85px;
            width: 85px;
            margin-bottom: 20px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            &:last-child {
                margin-bottom: 0; } } }
    .main_photo {
        width: calc(100% - 110px);
        height: 100%;
        max-height: 400px; }
    .price {
        font-size: 24px;
        font-weight: 400;
        line-height: 44px;
        letter-spacing: 1.2px;
        color: $color4;
        float: left; }

    @media (max-width: 1060px) {
        .product_deck {
            padding-left: 40px; } }
    @media (max-width: 940px) {
        .container {
            margin-bottom: 80px; }
        .product_gallery {
            height: auto;
            flex-direction: row;
            #product_gallery {
                width: 100%;
                height: 95px;
                margin-top: 20px;
                margin-right: 0;
                white-space: nowrap;
                order: 2;
                .image-holder {
                    margin-bottom: 0;
                    margin-right: 20px;
                    &:last-child {
                        margin-right: 0; } }
                &::-webkit-scrollbar {
                    width: auto;
                    height: 6px; }
                &::-webkit-scrollbar-track {
                    background: #f8f7f7;
                    border: 1px solid #efeeee; }
                &::-webkit-scrollbar-thumb {
                    background-color: $main-color-hover;
                    border: 2px solid #efeeee; } }
            .main_photo {
                width: 100%;
                order: 1; } } }
    @media (max-width: 800px) {
        .product_deck {
            padding-left: 26px;
            .block_text {
                margin-bottom: 26px; }
            .btn {
                padding: 12px 20px; } }
        .price {
            font-size: 20px;
            line-height: 38px; } }
    @media (max-width: 640px) {
        .container {
            margin-bottom: 40px; }
        .product_gallery {
            width: 100%;
            max-width: 400px;
            margin-bottom: 26px; }
        .product_deck {
            width: 100%;
            padding-left: 0; }
        h3 {
            line-height: 1.2;
            margin-bottom: 20px; } } }

