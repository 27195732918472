@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto/Roboto-Medium.eot");
  src: url("../fonts/roboto/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto/Roboto-Medium.woff") format("woff"), url("../fonts/roboto/Roboto-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Segoe UI";
  src: url("../fonts/SegoeUI/SegoeUIBold.eot");
  src: url("../fonts/SegoeUI/SegoeUIBold.eot?#iefix") format("embedded-opentype"), url("../fonts/SegoeUI/SegoeUIBold.woff") format("woff"), url("../fonts/SegoeUI/SegoeUIBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Segoe UI";
  src: url("../fonts/SegoeUI/SegoeUIRegular.eot");
  src: url("../fonts/SegoeUI/SegoeUIRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/SegoeUI/SegoeUIRegular.woff") format("woff"), url("../fonts/SegoeUI/SegoeUIRegular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Segoe UI";
  src: url("../fonts/SegoeUI/SegoeUILight.eot");
  src: url("../fonts/SegoeUI/SegoeUILight.eot?#iefix") format("embedded-opentype"), url("../fonts/SegoeUI/SegoeUILight.woff") format("woff"), url("../fonts/SegoeUI/SegoeUILight.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes notification {
  from {
    opacity: 0;
    transform: translateY(20px) translateX(-50%) translateZ(0);
  }
  10% {
    opacity: 1;
    transform: translateY(0) translateX(-50%) translateZ(0);
  }
  90% {
    opacity: 1;
    transform: translateY(0) translateX(-50%) translateZ(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px) translateX(-50%) translateZ(0);
  }
}

.fadeIn {
  animation: fadeIn 0.75s;
  animation-fill-mode: both;
}

.fadeOut {
  animation: fadeOut 0.75s;
  animation-fill-mode: both;
}

.notification {
  animation: notification 2s;
  animation-fill-mode: both;
}

button, .btn, input[type="button"] {
  border: none;
  background: none;
  cursor: pointer;
  display: inline-block;
  background-color: #dda14c;
  padding: 15px 29px;
  line-height: 1em;
  font-size: 14px;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  transition: background 100ms;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

button.focus, button:focus, button:hover, button:active, .btn.focus, .btn:focus, .btn:hover, .btn:active, input[type="button"].focus, input[type="button"]:focus, input[type="button"]:hover, input[type="button"]:active {
  text-decoration: none;
  background-color: #110800;
  color: #ffffff;
}

button:active, button:focus, .btn:active, .btn:focus, input[type="button"]:active, input[type="button"]:focus {
  outline: none;
}

.form-group {
  position: relative;
}

.form-group.error .error-label {
  opacity: 1;
  transition: opacity .3s;
}

.form-group.error .input-line {
  background: #f41f52;
  width: 100%;
}

.form-group .input-holder:after {
  background: #f41f52;
}

.form-group .error-label {
  color: #ff5656;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.9rem;
  opacity: 0;
  position: absolute;
  right: 0;
  bottom: -2.1rem;
  transition: opacity .3s;
}

.form-group .form-holder.error > * {
  border-color: #ff5656 !important;
}

.checkbox {
  position: relative;
}

.checkbox input[type="checkbox"] {
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.checkbox input[type="checkbox"]:checked + label:after {
  opacity: 1;
  transition: all 0.35s;
}

.checkbox input[type="checkbox"] + label {
  padding-left: 25px;
  position: relative;
  line-height: 16px;
  cursor: pointer;
}

.checkbox input[type="checkbox"] + label:before {
  width: 16px;
  height: 16px;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  margin-top: -8px;
}

.checkbox input[type="checkbox"] + label:after {
  width: 6px;
  height: 6px;
  content: "";
  position: absolute;
  top: 50%;
  left: 5px;
  background: #ffffff;
  margin-top: -3px;
  transition: all 0.35s;
  opacity: 0;
}

.on_off input[type="checkbox"] {
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.on_off input[type="checkbox"]:checked + label:after {
  background: #ffffff;
  left: 32px;
  transition: all 0.35s;
}

.on_off input[type="checkbox"] + label {
  padding-left: 60px;
  position: relative;
  line-height: 20px;
  cursor: pointer;
}

.on_off input[type="checkbox"] + label:before {
  width: 50px;
  height: 20px;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 20px;
  margin-top: -10px;
}

.on_off input[type="checkbox"] + label:after {
  width: 16px;
  height: 16px;
  content: "";
  position: absolute;
  top: 50%;
  left: 2px;
  border: 1px solid #ffffff;
  background: #fff;
  margin-top: -9px;
  transition: all 0.35s;
  border-radius: 20px;
}

.radio {
  position: relative;
}

.radio input[type="radio"] {
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.radio input[type="radio"]:checked + label:after {
  opacity: 1;
  transition: all 0.35s;
}

.radio input[type="radio"] + label {
  padding-left: 25px;
  position: relative;
  line-height: 16px;
  cursor: pointer;
}

.radio input[type="radio"] + label:before {
  width: 16px;
  height: 16px;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 16px;
  margin-top: -8px;
}

.radio input[type="radio"] + label:after {
  width: 6px;
  height: 6px;
  content: "";
  position: absolute;
  top: 50%;
  left: 5px;
  background: #ffffff;
  border-radius: 6px;
  margin-top: -3px;
  transition: all 0.35s;
  opacity: 0;
}

.input-file {
  cursor: pointer;
  position: relative;
}

.input-file label {
  cursor: pointer;
  display: block;
  transition: all .4s;
}

.input-file input {
  cursor: pointer;
  display: inline-block;
  left: 0;
  opacity: 0.01;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
}

a {
  color: #ffffff;
  transition: all 0.35s;
  text-decoration: none;
}

p {
  margin-top: 0;
}

.more {
  background-color: #dda14c;
  padding: 6px 19px;
  font-family: "Segoe UI";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
  display: inline-block;
}

.more.focus, .more:focus, .more:hover, .more:active {
  text-decoration: none;
  background-color: #110800;
  color: #ffffff;
}

.more:active, .more:focus {
  outline: none;
}

.hamburger-menu {
  display: none;
  background-color: initial;
  color: #dda14c;
  width: 32px;
  height: 27px;
  cursor: pointer;
  z-index: 99;
  margin-left: 16px;
}

.hamburger-menu:hover, .hamburger-menu:active, .hamburger-menu:focus, .hamburger-menu:visited {
  background-color: initial;
}

@media (max-width: 800px) {
  .hamburger-menu {
    display: block;
  }
}

@media (max-width: 640px), (max-height: 460px) {
  .hamburger-menu {
    width: 30px;
  }
}

.bar,
.bar:after,
.bar:before {
  width: 32px;
  height: 3px;
}

@media (max-width: 640px), (max-height: 460px) {
  .bar,
  .bar:after,
  .bar:before {
    width: 30px;
  }
}

.bar {
  position: relative;
  transform: translateY(12px);
  background: #dda14c;
  transition: all 0ms 300ms;
}

.bar.animate {
  background: rgba(255, 255, 255, 0);
}

.bar:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 12px;
  background: #dda14c;
  transition: bottom 300ms 300ms ease, transform 300ms ease;
}

@media (max-width: 575px), (max-height: 460px) {
  .bar:before {
    bottom: 13px;
  }
}

.bar:after {
  content: "";
  position: absolute;
  left: 0;
  top: 12px;
  background: #dda14c;
  transition: top 300ms 300ms ease, transform 300ms ease;
}

@media (max-width: 575px), (max-height: 460px) {
  .bar:after {
    top: 13px;
  }
}

.bar.animate:after {
  top: 0;
  transform: rotate(45deg);
  transition: top 300ms ease, transform 300ms 300ms ease;
}

.bar.animate:before {
  bottom: 0;
  transform: rotate(-45deg);
  transition: bottom 300ms ease, transform 300ms 300ms ease;
}

.animate-menu {
  top: 0 !important;
  bottom: 0 !important;
}

@media (max-width: 680px) {
  .animate-menu {
    bottom: auto !important;
  }
}

* {
  box-sizing: border-box;
}

html {
  font: 18px/1 Segoe UI, "Arial", sans-serif;
  color: #ffffff;
}

body {
  margin: 0;
  font-smoothing: antialiased;
  tap-highlight-color: transparent;
}

body.fixed {
  overflow: hidden;
}

body.fixed.client-ios {
  position: fixed;
  height: 100%;
  width: 100%;
}

a {
  border: none;
  outline: none;
}

p {
  margin-bottom: 20px;
  letter-spacing: 0.8px;
}

p:last-child {
  margin-bottom: 0;
}

img {
  border: none;
  outline: none;
  max-width: 100%;
  vertical-align: middle;
}

ul {
  padding: 0;
  margin: 0;
}

ul li {
  list-style: none;
}

ul.list-inline > li {
  display: inline-block;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 30px;
}

h1 {
  font-size: 2.5714285714285716rem;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }
}

h2 {
  font-size: 36px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 60px;
  position: relative;
  letter-spacing: 7.2px;
}

h2:before {
  content: '';
  position: absolute;
  left: 50%;
  width: 109px;
  height: 17px;
  bottom: -35px;
  background: url("../img/icons/border.png");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  transform: translateX(-50%);
}

@media (max-width: 1060px) {
  h2 {
    font-size: 30px;
    margin-bottom: 46px;
  }
  h2:before {
    bottom: -30px;
  }
}

@media (max-width: 680px) {
  h2 {
    font-size: 26px;
    margin-bottom: 40px;
  }
  h2:before {
    width: 90px;
    bottom: -26px;
  }
}

@media (max-width: 520px) {
  h2 {
    font-size: 24px;
  }
}

h3 {
  position: relative;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  text-transform: uppercase;
  letter-spacing: 4.8px;
}

@media (max-width: 1060px) {
  h3 {
    font-size: 22px;
  }
}

@media (max-width: 680px) {
  h3 {
    font-size: 20px;
  }
}

h4 {
  font-weight: 400;
  font-size: 22px;
  letter-spacing: 1.2px;
}

@media (max-width: 680px) {
  h4 {
    font-size: 19px;
  }
}

h5 {
  font-size: 1.2857142857142858rem;
}

@media screen and (max-width: 768px) {
  h5 {
    font-size: 1.1428571428571428rem;
  }
}

h6 {
  font-size: 1.1428571428571428rem;
}

hr {
  border: 0;
  display: block;
  border-top: 1px solid #353434;
  height: 1px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  clear: both;
}

label {
  border-radius: 0;
}

button, input, select, textarea, a {
  font: 18px/1 Segoe UI, "Arial", sans-serif;
  font-size: 1rem;
}

button:focus, button:active, input:focus, input:active, select:focus, select:active, textarea:focus, textarea:active, a:focus, a:active {
  outline: none;
}

select {
  appearance: none;
  border: 1px solid #353434;
  outline: none;
  background: none;
  border-radius: 0;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: none;
}

input,
textarea {
  outline: none;
  font: 18px/1 Segoe UI, "Arial", sans-serif;
  color: #353434;
  font-size: 1rem;
  border-radius: 0;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
  background: inherit;
  transition: background-color 5000s ease-in-out 0s;
}

input::placeholder,
textarea::placeholder {
  font: 18px/1 Segoe UI, "Arial", sans-serif;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.8px;
}

input:-webkit-autofill,
textarea:-webkit-autofill {
  -webkit-box-shadow: inherit !important;
  -webkit-text-fill-color: #ffffff !important;
  color: #ffffff !important;
}

textarea {
  resize: none;
}

input {
  appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='number']::-webkit-outer-spin-button, input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.container {
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  padding-left: 20px;
  padding-right: 20px;
}

.hidden {
  display: none !important;
}

@media (max-width: $screen-sm-max) {
  .sm-hidden {
    display: none !important;
  }
}

@media (max-width: $screen-xs-max) {
  .xs-hidden {
    display: none !important;
  }
}

.image-holder {
  overflow: hidden;
  position: relative;
  text-align: center;
  vertical-align: middle;
}

.image-holder img {
  object-fit: cover;
  vertical-align: middle;
  height: 100%;
  width: 100%;
}

.image-holder .fake-img {
  opacity: 0;
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.image-holder .img {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.table-holder {
  max-width: 100%;
  overflow: auto;
}

.flex-vertical {
  display: flex;
  height: 100%;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

.align-center,
.text-center {
  text-align: center;
}

.align-right,
.text-right {
  text-align: right;
}

.align-left,
.text-left {
  text-align: left;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.nowrap {
  white-space: nowrap;
}

.clearfix:before, .clearfix:after {
  content: '';
  height: 0;
  display: table;
  clear: both;
}

.inline-layout {
  font-size: 0;
  letter-spacing: 0;
}

.inline-layout > * {
  display: inline-block;
  vertical-align: middle;
  font: 18px/1 Segoe UI, "Arial", sans-serif;
}

.inline-layout.col-1 > * {
  width: 100%;
}

.inline-layout.col-2 > * {
  width: 50%;
}

.inline-layout.col-3 > * {
  width: 33.33333%;
}

.inline-layout.col-3 > * > .double {
  width: 66.66667%;
}

.inline-layout.col-4 > * {
  width: 25%;
}

.inline-layout.col-4 > * > .double {
  width: 50%;
}

.inline-layout.col-5 > * {
  width: 20%;
}

.inline-layout.col-5 > * > .double {
  width: 40%;
}

.inline-layout.col-6 > * {
  width: 16.66667%;
}

.inline-layout.col-6 > * > .double {
  width: 33.33333%;
}

.inline-layout.col-7 > * {
  width: 14.28571%;
}

.inline-layout.col-7 > * > .double {
  width: 28.57143%;
}

.inline-layout.col-8 > * {
  width: 12.5%;
}

.inline-layout.col-8 > * > .double {
  width: 266.66667%;
}

.inline-layout.col-9 > * {
  width: 11.11111%;
}

.inline-layout.col-9 > * > .double {
  width: 22.22222%;
}

.inline-layout.col-10 > * {
  width: 10%;
}

.inline-layout.col-10 > * > .double {
  width: 20%;
}

footer {
  background-color: #402104;
}

footer .container {
  padding-top: 25px;
  padding-bottom: 25px;
  text-align: center;
  position: relative;
}

footer .copyright {
  font-size: 14px;
}

footer .copyright:first-child {
  padding-bottom: 2px;
}

footer .copyright a {
  font-size: 15px;
}

footer .copyright a:hover {
  color: #dda14c;
}

footer .social {
  position: absolute;
  right: 20px;
  top: 30px;
}

footer .social li:not(:last-child) {
  margin-right: 14px;
}

@media (max-width: 420px) {
  footer .social {
    position: relative;
    top: auto;
    right: auto;
    margin-top: 10px;
  }
}

header {
  position: relative;
  z-index: 3;
}

header .container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 22px;
  padding-bottom: 22px;
}

header .logo {
  height: 62px;
  width: 51px;
}

header .menu {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

header nav {
  flex-grow: 1;
  padding: 0 24px;
}

header .list_nav {
  max-width: 660px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

header .item_nav {
  margin: 0 6px;
  cursor: pointer;
  padding: 4px 0;
}

header .item_nav a {
  color: #ffffff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 2.8px;
  display: inline-block;
  position: relative;
}

header .item_nav a:before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4px;
  background-color: inherit;
  transition: 0.25s;
}

header .item_nav.active a:before {
  background-color: #dda14c;
  transition: 0.25s;
}

header .item_nav:hover a:before {
  background-color: #dda14c;
  transition: 0.25s;
}

header .social li:not(:last-child) {
  margin-right: 7px;
}

@media (max-width: 800px) {
  header .container {
    padding: 6px 20px;
  }
  header .menu {
    position: absolute;
    z-index: 99;
    top: -120px;
    bottom: 120px;
    background-color: #402104;
    left: 0;
    right: 0;
    padding-right: 70px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
  }
  header .menu .list_nav {
    justify-content: center;
  }
  header .menu .item_nav:before {
    bottom: 2px;
  }
}

@media (max-width: 680px) {
  header .menu {
    top: -200px;
    padding: 20px;
  }
  header .menu .item_nav {
    width: 100%;
    text-align: center;
  }
}

.home header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.wrapper_wh header .menu {
  background-color: #ffffff;
}

.wrapper_wh header a {
  color: #212121;
}

@media (max-width: 680px) {
  .wrapper_wh header .menu {
    padding: 20px;
  }
}

.chromeperfectpixel-initial-block iframe,
#chromeperfectpixel-supported-by {
  display: none !important;
}

#__bs_notify__ {
  display: none !important;
  opacity: 0 !important;
}

* {
  transition: all .3s;
}

body {
  -webkit-text-size-adjust: none;
}

body.no-scroll {
  overflow: hidden;
}

@media screen and (max-width: 1024px) {
  body.no-scroll {
    width: 100%;
    height: 100vh;
    position: fixed;
  }
}

@media (max-width: 520px) {
  .block_text p {
    font-size: 14px !important;
  }
}

.lg-outer .lg-thumb {
  margin-left: auto;
  margin-right: auto;
}

.pagination li {
  margin: 6px;
  border: 1px solid #ddd;
}

.pagination li.active {
  border-color: #dda14c;
}

.pagination li.active .page-link {
  color: #dda14c;
}

.pagination li:hover {
  border-color: #dda14c;
}

.pagination li:hover .page-link {
  color: #dda14c;
}

.pagination li a {
  font-size: 22px;
  line-height: 0.8;
  padding: 6px 10px 8px;
  display: inline-block;
}

.pagination .disabled {
  opacity: 0.3;
}

.block_text ul, .block_text ol {
  padding-left: 26px;
  width: 100%;
}

.block_text ul li {
  list-style-type: disc;
}

.block_text ol li {
  list-style-type: decimal;
}

.wrapper_error {
  text-align: center;
  min-height: 100vh;
  background: #e7cca5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0 120px;
}

.wrapper_error .logo {
  margin-bottom: 40px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  display: none;
  opacity: 0;
}

.modal:target {
  z-index: 10;
  display: block;
  opacity: 1;
  transition: opacity 0.35s;
}

.modal::-webkit-scrollbar {
  width: 10px;
}

.modal::-webkit-scrollbar-track {
  background: #f8f7f7;
  border: 1px solid #efeeee;
}

.modal::-webkit-scrollbar-thumb {
  background-color: #110800;
  border: 2px solid #efeeee;
}

.modal .overlay {
  z-index: 11;
  position: absolute;
  background: #402104;
  opacity: 0.6;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  display: block;
  transition: opacity 0.5s;
}

.modal .modal_div {
  will-change: transform;
  position: absolute;
  background: #fff;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  max-width: 505px;
  max-height: calc(100vh - 40px);
  overflow: auto;
  transition: opacity 0.35s;
  padding: 50px;
  min-width: 360px;
  z-index: 12;
  color: #212121;
  box-shadow: 0 0 23px 12px rgba(0, 0, 0, 0.48);
  text-align: center;
}

.modal .modal_div img {
  margin-bottom: 30px;
}

.modal .modal_div h3 {
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  text-transform: uppercase;
  letter-spacing: 4.8px;
}

.modal .modal_div p {
  text-align: left;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.8px;
}

@media (max-width: 480px) {
  .modal .modal_div {
    min-width: 90%;
    padding: 26px;
  }
}

.modal .modal_close {
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: url("../img/icons/close.svg") no-repeat center;
  background-size: 100%;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
}

.block_home {
  position: relative;
}

.block_home:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #402104;
  opacity: 0.3;
}

.block_home .slider_top {
  position: relative;
  z-index: 2;
  text-align: center;
  display: flex;
}

.block_home .slider_top .swiper-slide {
  height: auto;
  padding: 136px 60px 80px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.block_home .slider_top .swiper-slide > * {
  position: relative;
  z-index: 2;
}

.block_home .slider_top .logo_top {
  margin: 0 auto 94px;
  width: 129px;
  height: 157px;
}

.block_home .slider_top h2 {
  font-size: 30px;
  letter-spacing: 1.5px;
  max-width: 880px;
  margin: auto;
  margin-bottom: 22px;
}

.block_home .slider_top h2:before {
  display: none;
}

.block_home .slider_top p {
  line-height: 1.4;
}

.block_home .slider_top .block_text {
  max-width: 712px;
  margin: auto;
  margin-bottom: 50px;
  font-size: 16px;
}

.block_home .slider_top .nav_slider {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  padding-left: 20px;
  padding-right: 20px;
}

.block_home .slider_top .pagination_slider {
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  padding-left: 20px;
  padding-right: 20px;
}

.block_home .slider_top .pagination_slider .swiper-pagination {
  right: 20px;
  transform: translateY(-100%);
}

.block_home .slider_top .pagination_slider .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 10px 0;
  display: block;
  width: 13px;
  height: 13px;
  border: 2px solid #dda14c;
  background: inherit;
  opacity: 1;
}

.block_home .slider_top .pagination_slider .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #dda14c;
}

.block_home .slider_top .pagination_slider .swiper-pagination-bullets .swiper-pagination-bullet:hover {
  background-color: #dda14c;
}

.block_home .slider_top .slider_top-button-prev, .block_home .slider_top .slider_top-button-next {
  position: absolute;
  left: 20px;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background: url("../img/icons/slider-button-prev.png");
  background-size: 25px 17px;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
}

.block_home .slider_top .slider_top-button-prev:hover, .block_home .slider_top .slider_top-button-next:hover {
  border: none;
  outline: none;
}

.block_home .slider_top .slider_top-button-prev:focus, .block_home .slider_top .slider_top-button-next:focus {
  border: none;
  outline: none;
}

.block_home .slider_top .slider_top-button-next {
  left: auto;
  right: 20px;
  background: url("../img/icons/slider-button-next.png");
  background-size: 25px 17px;
  background-position: center;
  background-repeat: no-repeat;
}

.block_home .slider_top .cube {
  position: absolute;
  top: 19%;
  left: 12%;
  z-index: 0;
  width: 105px;
  height: 105px;
  background-color: #402104;
  opacity: 0.7;
  background: url("../img/icons/icon-3c.png") no-repeat center;
  background-size: contain;
}

.block_home .slider_top .bord {
  position: absolute;
  top: 33%;
  right: 13%;
  z-index: 0;
  width: 130px;
  height: 50px;
  background-color: #402104;
  opacity: 0.7;
  background: url("../img/icons/border_dub.png") no-repeat center;
  background-size: contain;
}

.block_home .slider_top .dot {
  position: absolute;
  top: 78%;
  left: 18%;
  z-index: 0;
  width: 60px;
  height: 42px;
  background-color: #402104;
  opacity: 0.7;
  background: url("../img/icons/cub.png") no-repeat center;
  background-size: contain;
}

@media (max-width: 800px) {
  .block_home .slider_top .swiper-slide {
    padding: 74px 60px;
  }
  .block_home .slider_top h2 {
    font-size: 26px;
  }
  .block_home .slider_top .logo_top {
    margin-bottom: 40px;
  }
  .block_home .slider_top .block_text {
    margin-bottom: 36px;
  }
  .block_home .slider_top .cube {
    width: 75px;
    height: 75px;
  }
  .block_home .slider_top .bord {
    width: 90px;
  }
  .block_home .slider_top .dot {
    width: 46px;
  }
  .block_home .slider_top .pagination_slider {
    bottom: 16px;
  }
  .block_home .slider_top .pagination_slider .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 6px 0;
  }
}

@media (max-width: 680px) {
  .block_home .slider_top .swiper-slide {
    padding: 56px 60px;
  }
}

@media (max-width: 520px) {
  .block_home .slider_top .cube, .block_home .slider_top .bord, .block_home .slider_top .dot {
    display: none;
  }
  .block_home .slider_top h2 {
    font-size: 24px;
  }
  .block_home .slider_top .logo_top {
    width: 105px;
    height: 130px;
  }
}

.block_categories {
  background-color: #402104;
}

.block_categories .list_categories {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}

.block_categories .item_categories {
  width: 25%;
  position: relative;
  overflow: hidden;
}

.block_categories .item_categories .image-holder {
  height: 270px;
}

.block_categories .item_categories .name_categories {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
  background-color: #110800;
  padding: 7px 17px;
  cursor: pointer;
}

.block_categories .item_categories .hover_desc {
  background-color: rgba(17, 8, 0, 0.7);
  position: absolute;
  z-index: 3;
  top: 100%;
  right: 0;
  bottom: 100%;
  left: 0;
  height: 270px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  text-align: center;
  transition: 0.25s;
  padding: 46px;
}

.block_categories .item_categories .hover_desc > * {
  width: 100%;
}

.block_categories .item_categories .hover_desc .more {
  width: auto;
}

.block_categories .item_categories .hover_desc h3 {
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 18px;
}

.block_categories .item_categories .hover_desc p {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.block_categories .item_categories:hover .name_categories {
  top: -100%;
}

.block_categories .item_categories:hover .hover_desc {
  top: 0;
  bottom: 0;
  transition: 0.25s;
}

@media (max-width: 800px) {
  .block_categories .item_categories {
    width: 50%;
  }
}

@media (max-width: 680px) {
  .block_categories .item_categories .hover_desc h3 {
    font-size: 20px;
    margin-bottom: 16px;
  }
}

@media (max-width: 480px) {
  .block_categories .item_categories {
    width: 100%;
  }
}

.block_recommend {
  background-color: #402104;
  text-align: center;
}

.block_recommend .container {
  padding-top: 80px;
  padding-bottom: 20px;
}

.block_recommend .container p {
  color: #e5e5e5;
  font-family: "Segoe UI";
  font-size: 16px;
  font-weight: 300;
  max-width: 760px;
  margin: auto;
}

@media (max-width: 800px) {
  .block_recommend .container {
    padding-top: 36px;
    padding-bottom: 0;
  }
}

.list_product {
  margin-top: 90px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -10px;
  margin-right: -10px;
}

.item_product {
  margin-bottom: 50px;
  width: 25%;
  max-width: 330px;
  min-width: 250px;
  padding: 10px;
}

.item_product a {
  position: relative;
  display: inline-block;
  margin-bottom: 19px;
  overflow: hidden;
}

.item_product a:not(.name):before {
  content: '+';
  color: #110800;
  font-size: 60px;
  line-height: 295px;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 100%;
  left: 0;
  z-index: 2;
  height: 325px;
  background-color: rgba(221, 161, 76, 0.85);
  transition: 0.25s;
}

.item_product a:not(.name):hover:before {
  top: 0;
  bottom: 0;
  transition: 0.25s;
}

.item_product .image-holder {
  height: 325px;
}

.item_product .name {
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 16px;
}

.item_product .name:hover {
  color: #dda14c;
}

.item_product .price {
  color: #dda14c;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
}

@media (max-width: 1060px) {
  .item_product .item_product {
    width: 33%;
    margin-bottom: 30px;
  }
}

@media (max-width: 800px) {
  .item_product .list_product {
    margin-top: 46px;
  }
  .item_product .item_product {
    width: 50%;
    margin-bottom: 20px;
  }
  .item_product .item_product a {
    margin-bottom: 14px;
  }
  .item_product .item_product .name {
    margin-bottom: 14px;
  }
}

@media (max-width: 680px) {
  .item_product .list_product {
    margin-top: 26px;
    margin-bottom: 8px;
  }
  .item_product .list_product a:before {
    height: 290px;
    line-height: 275px;
  }
  .item_product .list_product .image-holder {
    height: 290px;
  }
}

@media (max-width: 560px) {
  .item_product .item_product {
    width: 100%;
    margin-bottom: 10px;
  }
  .item_product .item_product a:before {
    height: 260px;
    line-height: 240px;
  }
  .item_product .item_product .image-holder {
    height: 260px;
  }
  .item_product .item_product .name {
    margin-bottom: 8px;
  }
}

.block_about {
  background-color: #402104;
}

.block_about .container {
  padding-top: 80px;
  padding-bottom: 160px;
  display: flex;
  flex-wrap: wrap;
}

.block_about .container > * {
  width: 50%;
}

.block_about h2 {
  text-align: center;
  width: 100%;
}

.block_about .image-holder {
  max-width: 610px;
  position: relative;
  overflow: visible;
}

.block_about .image-holder:before {
  content: '';
  position: absolute;
  bottom: -52px;
  left: 37px;
  width: 105px;
  height: 105px;
  background: url("../img/icons/icon-3c.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.block_about .block_text {
  padding-left: 104px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.block_about .block_text p {
  color: #e5e5e5;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
}

@media (max-width: 1060px) {
  .block_about .container {
    padding-top: 0;
    padding-bottom: 100px;
  }
  .block_about .block_text {
    padding-left: 46px;
  }
}

@media (max-width: 800px) {
  .block_about .container {
    justify-content: center;
  }
  .block_about .image-holder {
    width: 100%;
    max-width: 100%;
    order: 2;
  }
  .block_about .block_text {
    order: 1;
    width: 100%;
    padding-left: 0;
    margin-bottom: 36px;
  }
}

@media (max-width: 520px) {
  .block_about .container {
    padding-bottom: 76px;
  }
  .block_about .container .image-holder:before {
    width: 75px;
    height: 75px;
    bottom: -38px;
  }
}

.block_contact {
  background: url("../img/bg/Layer_7.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.block_contact:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #110800;
  opacity: 0.8;
}

.block_contact .container {
  position: relative;
  padding-top: 80px;
  padding-bottom: 60px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.block_contact .container > * {
  width: 100%;
}

.block_contact .container .form-group {
  width: 66%;
  margin-top: 70px;
  text-align: left;
}

.block_contact .container .form-group .row {
  margin-bottom: 20px;
}

.block_contact .container .form-group .form-holder > * {
  width: 100%;
}

.block_contact .container .form-group .form-holder input, .block_contact .container .form-group .form-holder textarea {
  border: 2px solid #ffffff;
  background: inherit;
  color: #ffffff;
  padding: 6px 10px 10px;
}

.block_contact .container .form-group .form-holder textarea {
  min-height: 90px;
}

.block_contact .container .form-group .col-3 .form-holder:not(:last-child) {
  padding-right: 20px;
}

.block_contact .container .call {
  width: 34%;
  margin-top: 70px;
  padding-left: 126px;
  text-align: left;
}

.block_contact .container .call .phone {
  padding-left: 54px;
  background: url("../img/icons/Layer_17.png");
  background-size: 20px 35px;
  background-position: 7px 0;
  background-repeat: no-repeat;
  margin-bottom: 32px;
}

.block_contact .container .call .phone a:only-of-type {
  line-height: 36px;
}

.block_contact .container .call .phone a {
  font-size: 16px;
  display: block;
  margin-bottom: 6px;
}

.block_contact .container .call .phone a:hover {
  color: #dda14c;
}

.block_contact .container .call .hours {
  padding-left: 54px;
  background: url("../img/icons/Layer_16.png");
  background-size: 35px;
  background-position: left;
  background-repeat: no-repeat;
}

.block_contact .container .call .hours p {
  font-size: 16px;
  margin-bottom: 6px;
}

.block_contact .container h3 {
  color: #dda14c;
  font-size: 20px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  letter-spacing: 4px;
  margin-bottom: 34px;
}

.block_contact .container p {
  max-width: 764px;
  margin: auto;
  color: #e5e5e5;
  font-size: 16px;
  font-weight: 300;
  line-height: 18px;
}

@media (max-width: 1060px) {
  .block_contact .container {
    padding-top: 46px;
    padding-bottom: 36px;
  }
  .block_contact .container .form-group {
    margin-top: 35px;
  }
  .block_contact .container .call {
    margin-top: 35px;
    padding-left: 50px;
  }
  .block_contact h3 {
    margin-bottom: 46px;
  }
}

@media (max-width: 800px) {
  .block_contact .container .form-group {
    width: 100%;
  }
  .block_contact .container .call {
    width: 100%;
    padding-left: 0;
  }
  .block_contact .container .call .phone, .block_contact .container .call .hours {
    display: inline-block;
  }
  .block_contact .container .call .hours {
    margin-left: 40px;
  }
}

@media (max-width: 680px) {
  .block_contact .container .form-group {
    margin-top: 26px;
  }
  .block_contact .container .form-group .row {
    margin: 0;
  }
  .block_contact .container .form-group .form-holder {
    width: 100%;
    margin-bottom: 12px;
    padding-right: 0 !important;
  }
  .block_contact .container h3 {
    margin-bottom: 16px;
  }
  .block_contact .container .call {
    margin-top: 26px;
  }
  .block_contact .container .call .phone, .block_contact .container .call .hours {
    display: block;
  }
  .block_contact .container .call .hours {
    margin-left: 0;
  }
}

.block_product_card {
  color: #212121;
}

.block_product_card .container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 26px;
  margin-bottom: 125px;
}

.block_product_card .container > * {
  width: 50%;
}

.block_product_card h3 {
  text-align: center;
}

.block_product_card .product_deck {
  padding-left: 104px;
}

.block_product_card .product_deck .block_text {
  margin-bottom: 46px;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.8px;
}

.block_product_card .product_deck .btn {
  background-color: #402104;
  float: right;
}

.block_product_card .product_gallery {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  height: 400px;
  width: 50%;
}

.block_product_card #product_gallery {
  width: 90px;
  margin-right: 4px;
  padding-right: 2px;
  overflow: auto;
}

.block_product_card #product_gallery::-webkit-scrollbar {
  width: 6px;
}

.block_product_card #product_gallery::-webkit-scrollbar-track {
  background: #f8f7f7;
  border: 1px solid #efeeee;
}

.block_product_card #product_gallery::-webkit-scrollbar-thumb {
  background-color: #110800;
  border: 2px solid #efeeee;
}

.block_product_card #product_gallery .image-holder {
  display: inline-block;
  height: 85px;
  width: 85px;
  margin-bottom: 20px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.block_product_card #product_gallery .image-holder:last-child {
  margin-bottom: 0;
}

.block_product_card .main_photo {
  width: calc(100% - 110px);
  height: 100%;
  max-height: 400px;
}

.block_product_card .price {
  font-size: 24px;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: 1.2px;
  color: #402104;
  float: left;
}

@media (max-width: 1060px) {
  .block_product_card .product_deck {
    padding-left: 40px;
  }
}

@media (max-width: 940px) {
  .block_product_card .container {
    margin-bottom: 80px;
  }
  .block_product_card .product_gallery {
    height: auto;
    flex-direction: row;
  }
  .block_product_card .product_gallery #product_gallery {
    width: 100%;
    height: 95px;
    margin-top: 20px;
    margin-right: 0;
    white-space: nowrap;
    order: 2;
  }
  .block_product_card .product_gallery #product_gallery .image-holder {
    margin-bottom: 0;
    margin-right: 20px;
  }
  .block_product_card .product_gallery #product_gallery .image-holder:last-child {
    margin-right: 0;
  }
  .block_product_card .product_gallery #product_gallery::-webkit-scrollbar {
    width: auto;
    height: 6px;
  }
  .block_product_card .product_gallery #product_gallery::-webkit-scrollbar-track {
    background: #f8f7f7;
    border: 1px solid #efeeee;
  }
  .block_product_card .product_gallery #product_gallery::-webkit-scrollbar-thumb {
    background-color: #110800;
    border: 2px solid #efeeee;
  }
  .block_product_card .product_gallery .main_photo {
    width: 100%;
    order: 1;
  }
}

@media (max-width: 800px) {
  .block_product_card .product_deck {
    padding-left: 26px;
  }
  .block_product_card .product_deck .block_text {
    margin-bottom: 26px;
  }
  .block_product_card .product_deck .btn {
    padding: 12px 20px;
  }
  .block_product_card .price {
    font-size: 20px;
    line-height: 38px;
  }
}

@media (max-width: 640px) {
  .block_product_card .container {
    margin-bottom: 40px;
  }
  .block_product_card .product_gallery {
    width: 100%;
    max-width: 400px;
    margin-bottom: 26px;
  }
  .block_product_card .product_deck {
    width: 100%;
    padding-left: 0;
  }
  .block_product_card h3 {
    line-height: 1.2;
    margin-bottom: 20px;
  }
}

.block_about_us {
  background-color: #f5e2d0;
  color: #191919;
  padding-top: 68px;
  padding-bottom: 50px;
}

.block_about_us h2 {
  text-align: center;
}

.block_about_us .foto_about, .block_about_us .block_text {
  max-width: 820px;
  margin: auto;
  margin-bottom: 48px;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.8px;
}

.block_product_list {
  background-color: #402104;
  text-align: center;
}

.block_product_list .container {
  padding-top: 40px;
  padding-bottom: 40px;
}

.block_product_list .list_product {
  margin-top: 26px;
}

.block_contact_info {
  background-color: #f5e2d0;
  text-align: center;
}

.block_contact_info h2 {
  width: 100%;
  color: #212121;
  margin-bottom: 82px;
}

.block_contact_info .container {
  padding-top: 68px;
  padding-bottom: 100px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.block_contact_info .contact_desc {
  color: #212121;
  text-align: left;
  max-width: 418px;
  width: 35%;
  padding-right: 30px;
}

.block_contact_info .contact_desc .address, .block_contact_info .contact_desc .phone, .block_contact_info .contact_desc .mail {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 1.2px;
  color: #212121;
  display: block;
  margin-bottom: 14px;
  padding-left: 40px;
}

.block_contact_info .contact_desc .address {
  background: url("../img/icons/icon-pointer.png") no-repeat center;
  background-position: 0 50%;
}

.block_contact_info .contact_desc .mail {
  background: url("../img/icons/mail.png") no-repeat center;
  background-position: 0 50%;
}

.block_contact_info .contact_desc .phone {
  background: url("../img/icons/phone.png") no-repeat center;
  background-position: 0 50%;
}

.block_contact_info .map {
  max-width: 820px;
  width: 65%;
  height: 400px;
}

.block_contact_info .map * {
  color: black;
  transition: none;
}

@media (max-width: 960px) {
  .block_contact_info .contact_desc, .block_contact_info .map {
    width: 50%;
  }
}

@media (max-width: 800px) {
  .block_contact_info .contact_desc, .block_contact_info .map {
    width: 90%;
    text-align: center;
  }
  .block_contact_info .contact_desc > * {
    display: inline-block !important;
  }
}

@media (max-width: 800px) {
  .block_contact_info .container {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .block_contact_info .contact_desc, .block_contact_info .map {
    width: 100%;
  }
  .block_contact_info .contact_desc {
    margin-bottom: 10px;
  }
  .block_contact_info .contact_desc .address, .block_contact_info .contact_desc .phone, .block_contact_info .contact_desc .mail {
    margin-bottom: 8px;
    font-size: 17px;
  }
  .block_contact_info h2 {
    margin-bottom: 40px;
  }
  .block_contact_info h4 {
    margin-bottom: 16px;
  }
}
