@import "_mixins";

.block_contact {
    background: url("../img/bg/Layer_7.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #110800;
        opacity: 0.8; }
    .container {
        position: relative;
        padding-top: 80px;
        padding-bottom: 60px;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        > * {
            width: 100%; }
        .form-group {
            width: 66%;
            margin-top: 70px;
            text-align: left;
            .row {
                margin-bottom: 20px; }
            .form-holder {
                > * {
                    width: 100%; }
                input, textarea {
                    border: 2px solid #ffffff;
                    background: inherit;
                    color: $color;
                    padding: 6px 10px 10px; }
                textarea {
                    min-height: 90px; } }
            .col-3 {
                .form-holder {
                    &:not(:last-child) {
                        padding-right: 20px; } } } }


        .call {
            width: 34%;
            margin-top: 70px;
            padding-left: 126px;
            text-align: left;
            .phone {
                padding-left: 54px;
                background: url("../img/icons/Layer_17.png");
                background-size: 20px 35px;
                background-position: 7px 0;
                background-repeat: no-repeat;
                margin-bottom: 32px;
                a:only-of-type {
                    line-height: 36px; }
                a {
                    font-size: 16px;
                    display: block;
                    margin-bottom: 6px;
                    &:hover {
                        color: $color2; } } }
            .hours {
                padding-left: 54px;
                background: url("../img/icons/Layer_16.png");
                background-size: 35px;
                background-position: left;
                background-repeat: no-repeat;
                p {
                    font-size: 16px;
                    margin-bottom: 6px; } } }
        h3 {
            color: $color2;
            font-size: 20px;
            font-weight: 400;
            line-height: 18px;
            text-transform: uppercase;
            letter-spacing: 4px;
            margin-bottom: 34px; }
        p {
            max-width: 764px;
            margin: auto;
            color: #e5e5e5;
            font-size: 16px;
            font-weight: 300;
            line-height: 18px; } }

    @media (max-width: 1060px) {
        .container {
            padding-top: 46px;
            padding-bottom: 36px;
            .form-group {
                margin-top: 35px; }
            .call {
                margin-top: 35px;
                padding-left: 50px; } }
        h3 {
            margin-bottom: 46px; } }


    @media (max-width: 800px) {
        .container {
            .form-group {
                width: 100%; }
            .call {
                width: 100%;
                padding-left: 0;
                .phone, .hours {
                    display: inline-block; }
                .hours {
                    margin-left: 40px; } } } }

    @media (max-width: 680px) {
        .container {
            .form-group {
                margin-top: 26px;
                .row {
                    margin: 0; }
                .form-holder {
                    width: 100%;
                    margin-bottom: 12px;
                    padding-right: 0!important; } }

            h3 {
                margin-bottom: 16px; }
            .call {
                margin-top: 26px;
                .phone, .hours {
                    display: block; }
                .hours {
                    margin-left: 0; } } } } }
