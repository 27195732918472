@import "_mixins";

.block_about {
    background-color: #402104;
    .container {
        padding-top: 80px;
        padding-bottom: 160px;
        display: flex;
        flex-wrap: wrap;
        > * {
            width: 50%; } }
    h2 {
        text-align: center;
        width: 100%; }

    .image-holder {
        max-width: 610px;
        position: relative;
        overflow: visible;
        &:before {
            content: '';
            position: absolute;
            bottom: -52px;
            left: 37px;
            width: 105px;
            height: 105px;
            background: url("../img/icons/icon-3c.png");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center; } }
    .block_text {
        padding-left: 104px;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        p {
            color: #e5e5e5;
            font-size: 16px;
            font-weight: 300;
            line-height: 24px; } }

    @media (max-width: 1060px) {
        .container {
            padding-top: 0;
            padding-bottom: 100px; }
        .block_text {
            padding-left: 46px; } }
    @media (max-width: 800px) {
        .container {
            justify-content: center; }
        .image-holder {
            width: 100%;
            max-width: 100%;
            order: 2; }
        .block_text {
            order: 1;
            width: 100%;
            padding-left: 0;
            margin-bottom: 36px; } }
    @media (max-width: 520px) {
        .container {
            padding-bottom: 76px;
            .image-holder {
                &:before {
                    width: 75px;
                    height: 75px;
                    bottom: -38px; } } } } }

