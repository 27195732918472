@import "_mixins";

.block_contact_info {
    background-color: #f5e2d0;
    text-align: center;
    h2 {
        width: 100%;
        color: $color3;
        margin-bottom: 82px; }
    .container {
        padding-top: 68px;
        padding-bottom: 100px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center; }
    .contact_desc {
        color: $color3;
        text-align: left;
        max-width: 418px;
        width: 35%;
        padding-right: 30px;
        .address, .phone, .mail {
            font-size: 18px;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 1.2px;
            color: $color3;
            display: block;
            margin-bottom: 14px;
            padding-left: 40px; }
        .address {
            background: url("../img/icons/icon-pointer.png") no-repeat center;
            background-position: 0 50%; }
        .mail {
            background: url("../img/icons/mail.png") no-repeat center;
            background-position: 0 50%; }
        .phone {
            background: url("../img/icons/phone.png") no-repeat center;
            background-position: 0 50%; } }
    .map {
        max-width: 820px;
        width: 65%;
        height: 400px;
        * {
            color: black;
            transition: none; } }
    @media (max-width: 960px) {
        .contact_desc, .map {
            width: 50%; } }
    @media (max-width: 800px) {
        .contact_desc, .map {
            width: 90%;
            text-align: center; }
        .contact_desc {
            > * {
                display: inline-block!important; } } }
    @media (max-width: 800px) {
        .container {
            padding-top: 40px;
            padding-bottom: 40px; }
        .contact_desc, .map {
            width: 100%; }
        .contact_desc {
            margin-bottom: 10px;
            .address, .phone, .mail {
                margin-bottom: 8px;
                font-size: 17px; } }
        h2 {
            margin-bottom: 40px; }
        h4 {
            margin-bottom: 16px; } } }


