@import "_mixins";

.block_recommend {
    background-color: #402104;
    text-align: center;
    .container {
        padding-top: 80px;
        padding-bottom: 20px;
        p {
            color: #e5e5e5;
            font-family: "Segoe UI";
            font-size: 16px;
            font-weight: 300;
            max-width: 760px;
            margin: auto; } }
    @media (max-width: 800px) {
        .container {
            padding-top: 36px;
            padding-bottom: 0; } } }
.list_product {
    margin-top: 90px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -10px;
    margin-right: -10px; }
.item_product {
    margin-bottom: 50px;
    width: 25%;
    max-width: 330px;
    min-width: 250px;
    padding: 10px;
    a {
        position: relative;
        display: inline-block;
        margin-bottom: 19px;
        overflow: hidden;
        &:not(.name):before {
            content: '+';
            color: #110800;
            font-size: 60px;
            line-height: 295px;
            position: absolute;
            top: 100%;
            right: 0;
            bottom: 100%;
            left: 0;
            z-index: 2;
            height: 325px;
            background-color: rgba(221, 161, 76, 0.85);
            transition: 0.25s; }
        &:not(.name):hover {
            &:before {
                top: 0;
                bottom: 0;
                transition: 0.25s; } } }
    .image-holder {
        height: 325px; }
    .name {
        font-size: 18px;
        font-weight: 400;
        line-height: 18px;
        margin-bottom: 16px;
        &:hover {
            color: $color2; } }
    .price {
        color: $color2;
        font-size: 16px;
        font-weight: 700;
        line-height: 18px; }

    @media (max-width: 1060px) {
        .item_product {
            width: 33%;
            margin-bottom: 30px; } }
    @media (max-width: 800px) {
        .list_product {
            margin-top: 46px; }
        .item_product {
            width: 50%;
            margin-bottom: 20px;
            a {
                margin-bottom: 14px; }
            .name {
                margin-bottom: 14px; } } }
    @media (max-width: 680px) {
        .list_product {
            margin-top: 26px;
            margin-bottom: 8px;
            a {
                &:before {
                    height: 290px;
                    line-height: 275px; } }
            .image-holder {
                height: 290px; } } }
    @media (max-width: 560px) {
        .item_product {
            width: 100%;
            margin-bottom: 10px;
            a {
                &:before {
                    height: 260px;
                    line-height: 240px; } }
            .image-holder {
                height: 260px; }
            .name {
                margin-bottom: 8px; } } } }

