@import "_mixins";

.block_home {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #402104;
        opacity: 0.3; }

    .slider_top {
        position: relative;
        z-index: 2;
        text-align: center;
        display: flex;
        .swiper-slide {
            height: auto;
            padding: 136px 60px 80px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            > * {
                position: relative;
                z-index: 2; } }
        .logo_top {
            margin: 0 auto 94px;
            width: 129px;
            height: 157px; }
        h2 {
            font-size: 30px;
            letter-spacing: 1.5px;
            max-width: 880px;
            margin: auto;
            margin-bottom: 22px;
            &:before {
                display: none; } }
        p {
            line-height: 1.4; }

        .block_text {
            max-width: 712px;
            margin: auto;
            margin-bottom: 50px;
            font-size: 16px; }

        .nav_slider {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 100%;
            max-width: 1280px;
            padding-left: 20px;
            padding-right: 20px; }
        .pagination_slider {
            position: absolute;
            bottom: 40px;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 100%;
            max-width: 1280px;
            padding-left: 20px;
            padding-right: 20px;
            .swiper-pagination {
                right: 20px;
                transform: translateY(-100%); }
            .swiper-pagination-bullets .swiper-pagination-bullet {
                margin: 10px 0;
                display: block;
                width: 13px;
                height: 13px;
                border: 2px solid #dda14c;
                background: inherit;
                opacity: 1;
                &.swiper-pagination-bullet-active {
                    background-color: #dda14c; }
                &:hover {
                    background-color: #dda14c; } } }

        .slider_top-button-prev, .slider_top-button-next {
            position: absolute;
            left: 20px;
            width: 27px;
            height: 44px;
            margin-top: -22px;
            z-index: 10;
            cursor: pointer;
            background: url("../img/icons/slider-button-prev.png");
            background-size: 25px 17px;
            background-position: center;
            background-repeat: no-repeat;
            border: none;
            &:hover {
                border: none;
                outline: none; }
            &:focus {
                border: none;
                outline: none; } }
        .slider_top-button-next {
            left: auto;
            right: 20px;
            background: url("../img/icons/slider-button-next.png");
            background-size: 25px 17px;
            background-position: center;
            background-repeat: no-repeat; }

        .cube {
            position: absolute;
            top: 19%;
            left: 12%;
            z-index: 0;
            width: 105px;
            height: 105px;
            background-color: #402104;
            opacity: 0.7;
            background: url("../img/icons/icon-3c.png") no-repeat center;
            background-size: contain; }
        .bord {
            position: absolute;
            top: 33%;
            right: 13%;
            z-index: 0;
            width: 130px;
            height: 50px;
            background-color: #402104;
            opacity: 0.7;
            background: url("../img/icons/border_dub.png") no-repeat center;
            background-size: contain; }

        .dot {
            position: absolute;
            top: 78%;
            left: 18%;
            z-index: 0;
            width: 60px;
            height: 42px;
            background-color: #402104;
            opacity: 0.7;
            background: url("../img/icons/cub.png") no-repeat center;
            background-size: contain; } }
    @media (max-width: 800px) {

        .slider_top {
            .swiper-slide {
                padding: 74px 60px; }
            h2 {
                font-size: 26px; }
            .logo_top {
                margin-bottom: 40px; }
            .block_text {
                margin-bottom: 36px; }

            .cube {
                width: 75px;
                height: 75px; }
            .bord {
                width: 90px; }
            .dot {
                width: 46px; }
            .pagination_slider {
                bottom: 16px;
                .swiper-pagination-bullets .swiper-pagination-bullet {
                    margin: 6px 0; } } } }
    @media (max-width: 680px) {
        .slider_top {
            .swiper-slide {
                padding: 56px 60px; } } }

    @media (max-width: 520px) {
        .slider_top {
            .cube, .bord, .dot {
                display: none; }
            h2 {
                font-size: 24px; }
            .logo_top {
                width: 105px;
                height: 130px; } } } }

