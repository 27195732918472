@import "_mixins";

.block_product_list {
    background-color: #402104;
    text-align: center;
    .container {
        padding-top: 40px;
        padding-bottom: 40px; }
    .list_product {
        margin-top: 26px; } }
