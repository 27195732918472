@import "_mixins";

@keyframes fadeIn {
	from {
		opacity: 0; }

	to {
		opacity: 1; } }

@keyframes fadeOut {
	from {
		opacity: 1; }

	to {
		opacity: 0; } }

@keyframes notification {
	from {
		opacity: 0;
		transform: translateY(20px) translateX(-50%) translateZ(0); }

	10% {
		opacity: 1;
		transform: translateY(0) translateX(-50%) translateZ(0); }

	90% {
		opacity: 1;
		transform: translateY(0) translateX(-50%) translateZ(0); }

	to {
		opacity: 0;
		transform: translateY(-20px) translateX(-50%) translateZ(0); } }

.fadeIn {
	animation: fadeIn 0.75s;
	animation-fill-mode: both; }
.fadeOut {
	animation: fadeOut 0.75s;
	animation-fill-mode: both; }
.notification {
	animation: notification 2s;
	animation-fill-mode: both; }
