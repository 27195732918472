@import "_mixins";

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 0;
    display: none;
    opacity: 0;
    &:target {
        z-index: 10;
        display: block;
        opacity: 1;
        transition: opacity 0.35s; }

    &::-webkit-scrollbar {
        width: 10px; }
    &::-webkit-scrollbar-track {
        background: #f8f7f7;
        border: 1px solid #efeeee; }
    &::-webkit-scrollbar-thumb {
        background-color: $main-color-hover;
        border: 2px solid #efeeee; }

    .overlay {
        z-index: 11;
        position: absolute;
        background: $color4;
        opacity: 0.6;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        cursor: pointer;
        display: block;
        transition: opacity 0.5s; }
    .modal_div {
        will-change: transform;
        position: absolute;
        background: #fff;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        max-width: 505px;
        max-height: calc(100vh - 40px);
        overflow: auto;
        transition: opacity 0.35s;
        padding: 50px;
        min-width: 360px;
        z-index: 12;
        color: $color3;
        box-shadow: 0 0 23px 12px rgba(0, 0, 0, 0.48);
        text-align: center;
        img {
            margin-bottom: 30px; }
        h3 {
            font-size: 24px;
            font-weight: 400;
            line-height: 30px;
            text-transform: uppercase;
            letter-spacing: 4.8px; }
        p {
            text-align: left;
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;
            letter-spacing: 0.8px; } }
    @media (max-width: 480px) {
        .modal_div {
            min-width: 90%;
            padding: 26px; } }



    .modal_close {
        width: 20px;
        height: 20px;
        cursor: pointer;
        background: url("../img/icons/close.svg") no-repeat center;
        background-size: 100%;
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 1; } }

