@import "_mixins";

* {
    box-sizing: border-box; }

html {
    font: $font;
    color: $color; }

body {
    margin: 0;
    font-smoothing: antialiased;
    tap-highlight-color: transparent;

    &.fixed {
        overflow: hidden;

        &.client-ios {
            position: fixed;
            height: 100%;
            width: 100%; } } }

section {}

a {
    border: none;
    outline: none; }
// color: $main-color

p {
    margin-bottom: 20px;
    letter-spacing: 0.8px;
    &:last-child {
        margin-bottom: 0; } }

span {}

img {
    border: none;
    outline: none;
    max-width: 100%;
    vertical-align: middle; }

ul {
    padding: 0;
    margin: 0;
    li {
        list-style: none; }

    &.list-inline {
        & > li {
            display: inline-block; } } }

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 30px; }

h1 {
    font-size: 2.5714285714285716rem;
    @media screen and (max-width: 768px) {
        font-size: 2rem; } }

h2 {
    font-size: 36px;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 60px;
    position: relative;
    letter-spacing: 7.2px;
    &:before {
        content: '';
        position: absolute;
        left: 50%;
        width: 109px;
        height: 17px;
        bottom: -35px;
        background: url("../img/icons/border.png");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
        transform: translateX(-50%); }
    @media (max-width: 1060px) {
        font-size: 30px;
        margin-bottom: 46px;
        &:before {
            bottom: -30px; } }
    @media (max-width: 680px) {
        font-size: 26px;
        margin-bottom: 40px;
        &:before {
            width: 90px;
            bottom: -26px; } }
    @media (max-width: 520px) {
        font-size: 24px; } }
h3 {
    position: relative;
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    text-transform: uppercase;
    letter-spacing: 4.8px;
    @media (max-width: 1060px) {
        font-size: 22px; }
    @media (max-width: 680px) {
        font-size: 20px; } }

h4 {
    font-weight: 400;
    font-size: 22px;
    letter-spacing: 1.2px;
    @media (max-width: 680px) {
        font-size: 19px; } }

h5 {
    font-size: 1.2857142857142858rem;
    @media screen and (max-width: 768px) {
        font-size: 1.1428571428571428rem; } }

h6 {
    font-size: 1.1428571428571428rem; }

hr {
    border: 0;
    display: block;
    border-top: 1px solid $base-font-color;
    height: 1px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    clear: both; }

label {
    border-radius: 0; }

button, input, select, textarea, a {
    font: $font;
    font-size: 1rem;

    &:focus,
    &:active {
        outline: none; } }

//	SELECT
select {
    appearance: none;
    border: 1px solid $base-font-color;
    outline: none;
    background: none;
    border-radius: 0;

    &:-moz-focusring {
        color: transparent;
        text-shadow: none; } }
//	END SELECT

// INPUTS & TEXTAREA
input,
textarea {
    outline: none;
    font: $font;
    color: $base-font-color;
    font-size: 1rem;
    border-radius: 0;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        background: inherit;
        //-webkit-text-fill-color: #fff
        //-webkit-box-shadow: 0 0 0px 1000px #000 inset
        transition: background-color 5000s ease-in-out 0s; }

    &::placeholder {
        font: $font;
        color: $color;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.8px; }
    &:-webkit-autofill {
        -webkit-box-shadow: inherit !important;
        -webkit-text-fill-color: $color !important;
        color: $color !important; } }

textarea {
    resize: none; }

input {
    appearance: none; }

input[type='number'] {
    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
        -webkit-appearance: none; }
    -moz-appearance: textfield; }
//	END INPUTS & TEXTAREA


// GRID
.container {
    overflow: hidden;
    margin: 0 auto;
    width: 100%;
    max-width: 1280px;
    padding-left: 20px;
    padding-right: 20px; }

//@media #{$media-sm}
//  padding-left: 1rem
//  padding-right: 1rem
//  width: 100%

.hidden {
    display: none !important; }

.sm-hidden {
    @media #{$media-sm} {
        display: none !important; } }

.xs-hidden {
    @media #{$media-xs} {
        display: none !important; } }

// image-holder
.image-holder {
    overflow: hidden;
    position: relative;
    text-align: center;
    vertical-align: middle;

    img {
        object-fit: cover;
        vertical-align: middle;
        height: 100%;
        width: 100%; }

    .fake-img {
        opacity: 0;
        position: relative;
        height: 100%;
        width: 100%;
        z-index: 1; }

    .img {
        background: {
            position: 50% 50%;
            repeat: no-repeat;
            size: cover; }
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0; } }

.table-holder {
    max-width: 100%;
    overflow: auto; }

.flex-vertical {
    display: flex;
    height: 100%;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center; }

//	MARKUP
.align-center,
.text-center {
    text-align: center; }

.align-right,
.text-right {
    text-align: right; }

.align-left,
.text-left {
    text-align: left; }

.pull-left {
    float: left; }

.pull-right {
    float: right; }

.nowrap {
    white-space: nowrap; }

.clearfix {
    &:before,
    &:after {
        content: '';
        height: 0;
        display: table;
        clear: both; } }
