@import "_mixins";

.block_categories {
    background-color: $color4;
    .list_categories {
        display: flex;
        flex-wrap: wrap;
        margin: 0; }

    .item_categories {
        width: 25%;
        position: relative;
        overflow: hidden;
        .image-holder {
            height: 270px; }
        .name_categories {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-transform: uppercase;
            background-color: #110800;
            padding: 7px 17px;
            cursor: pointer; }
        .hover_desc {
            background-color: rgba(17, 8, 0, 0.7);
            position: absolute;
            z-index: 3;
            top: 100%;
            right: 0;
            bottom: 100%;
            left: 0;
            height: 270px;
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            justify-content: center;
            text-align: center;
            transition: 0.25s;
            padding: 46px;
            > * {
                width: 100%; }
            .more {
                width: auto; }
            h3 {
                font-size: 24px;
                font-weight: 400;
                line-height: 24px;
                text-transform: uppercase;
                margin-bottom: 18px; }
            p {
                font-size: 14px;
                font-weight: 400;
                line-height: 18px; } }
        &:hover {
            .name_categories {
                top: -100%; }
            .hover_desc {
                top: 0;
                bottom: 0;
                transition: 0.25s; } } }

    @media (max-width: 800px) {
        .item_categories {
            width: 50%; } }
    @media (max-width: 680px) {
        .item_categories {
            .hover_desc {
                h3 {
                    font-size: 20px;
                    margin-bottom: 16px; } } } }
    @media (max-width: 480px) {
        .item_categories {
            width: 100%; } } }
