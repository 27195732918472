@import "_mixins";

//----------------------------------
// DEV
//----------------------------------
.chromeperfectpixel-initial-block iframe,
#chromeperfectpixel-supported-by {
    display: none !important; }

#__bs_notify__ {
    display: none !important;
    opacity: 0 !important; }

* {
    transition: all .3s; }
//----------------------------------
// END DEV
//----------------------------------


body {
    // &::-webkit-scrollbar
    -webkit-text-size-adjust: none;
    &.no-scroll {
        overflow: hidden;
        @media screen and (max-width: 1024px) {
            width: 100%;
            height: 100vh;
            position: fixed; } } }

@media (max-width: 520px) {
    .block_text {
        p {
            font-size: 14px !important; } } }

.lg-outer .lg-thumb {
    margin-left: auto;
    margin-right: auto; }

.pagination {
    li {
        margin: 6px;
        border: 1px solid #ddd;
        &.active {
            border-color: $color2;
            .page-link {
                color: $color2; } }
        &:hover {
            border-color: $color2;
            .page-link {
                color: $color2; } }

        a {
            font-size: 22px;
            line-height: 0.8;
            padding: 6px 10px 8px;
            display: inline-block; } }
    .disabled {
        opacity: 0.3; } }

.block_text {
    ul, ol {
        padding-left: 26px;
        width: 100%; }
    ul li {
        list-style-type: disc; }
    ol li {
        list-style-type: decimal; } }

.wrapper_error {
    text-align: center;
    min-height: 100vh;
    background: #e7cca5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0 120px;
    .logo {
        margin-bottom: 40px; } }

