@import "_mixins";

// BUTTONS
button, .btn, input[type="button"] {
    border: none;
    background: none;
    cursor: pointer;
    display: inline-block;
    background-color: #dda14c;
    padding: 15px 29px;
    line-height: 1em;
    font-size: 14px;
    letter-spacing: 0.7px;
    text-transform: uppercase;
    transition: background 100ms;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &.focus, &:focus, &:hover, &:active {
        text-decoration: none;
        background-color: $main-color-hover;
        color: $color; }
    &:active, &:focus {
        outline: none; } }

.form-group {
    position: relative;

    &.error {
        .error-label {
            // display: block
            opacity: 1;
            transition: opacity .3s; } }

    &.error {
        .input-line {
            background: #f41f52;
            width: 100%; } }

    .input-holder {
        &:after {
            background: #f41f52; } }

    .error-label {
        color: $error-color;
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 1.9rem;
        opacity: 0;
        position: absolute;
        right: 0;
        bottom: -2.1rem;
        transition: opacity .3s; }
    .form-holder {
        &.error {
            > * {
                border-color: $error-color!important; } } } }


// END FORM

// CHECKBOX
.checkbox {
    position: relative;
    input[type="checkbox"] {
        width: 0;
        height: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        &:checked {
            + label {
                &:after {
                    opacity: 1;
                    transition: all 0.35s; } } }
        + label {
            padding-left: 25px;
            position: relative;
            line-height: 16px;
            cursor: pointer;
            &:before {
                width: 16px;
                height: 16px;
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                border: 2px solid $main-color;
                box-sizing: border-box;
                margin-top: -8px; }

            &:after {
                width: 6px;
                height: 6px;
                content: "";
                position: absolute;
                top: 50%;
                left: 5px;
                background: $main-color;
                margin-top: -3px;
                transition: all 0.35s;
                opacity: 0; } } } }

.on_off {
    input[type="checkbox"] {
        width: 0;
        height: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        &:checked {
            + label {
                &:after {
                    background: $main-color;
                    left: 32px;
                    transition: all 0.35s; } } }
        + label {
            padding-left: 60px;
            position: relative;
            line-height: 20px;
            cursor: pointer;
            &:before {
                width: 50px;
                height: 20px;
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                border: 2px solid $main-color;
                box-sizing: border-box;
                border-radius: 20px;
                margin-top: -10px; }

            &:after {
                width: 16px;
                height: 16px;
                content: "";
                position: absolute;
                top: 50%;
                left: 2px;
                border: 1px solid $main-color;
                background: #fff;
                margin-top: -9px;
                transition: all 0.35s;
                border-radius: 20px; } } } }

// END CHECKBOX

// RADIO
.radio {
    position: relative;
    input[type="radio"] {
        width: 0;
        height: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        &:checked {
            + label {
                &:after {
                    opacity: 1;
                    transition: all 0.35s; } } }
        + label {
            padding-left: 25px;
            position: relative;
            line-height: 16px;
            cursor: pointer;
            &:before {
                width: 16px;
                height: 16px;
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                border: 2px solid $main-color;
                box-sizing: border-box;
                border-radius: 16px;
                margin-top: -8px; }

            &:after {
                width: 6px;
                height: 6px;
                content: "";
                position: absolute;
                top: 50%;
                left: 5px;
                background: $main-color;
                border-radius: 6px;
                margin-top: -3px;
                transition: all 0.35s;
                opacity: 0; } } } }

// END RADIO


//	INPUT FILE
.input-file {
    cursor: pointer;
    position: relative;

    label {
        cursor: pointer;
        display: block;
        transition: all .4s; }

    input {
        cursor: pointer;
        display: inline-block;
        left: 0;
        opacity: 0.01;
        padding: 0;
        pointer-events: none;
        position: absolute;
        top: 0; } }
//&:hover + label, &:focus + label
//	END INPUT FILE

a {
    color: $main-color;
    transition: all 0.35s;
    text-decoration: none; }

p {
    margin-top: 0; }

.more {
    background-color: #dda14c;
    padding: 6px 19px;
    font-family: "Segoe UI";
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-transform: uppercase;
    display: inline-block;
    &.focus, &:focus, &:hover, &:active {
        text-decoration: none;
        background-color: $main-color-hover;
        color: $color; }

    &:active, &:focus {
        outline: none; } }

$bar-width: 32px;
$bar-height: 3px;
$bar-spacing: 12px;
$bar-spacing-max575: 13px;
$bar-color: $color2;
$bar-speed: 300ms;

.hamburger-menu {
    display: none;
    background-color: initial;
    color: $bar-color;
    //position: absolute
    //top: 11px
    //right: 17px
    //bottom: 0
    //margin: auto
    width: $bar-width;
    height: $bar-height + $bar-spacing*2;
    cursor: pointer;
    z-index: 99;
    margin-left: 16px;
    &:hover, &:active, &:focus, &:visited {
        background-color: initial; }
    @media (max-width: 800px) {
        display: block; }

    @media (max-width: 640px), (max-height: 460px) {
        width: 30px; } }

.bar,
.bar:after,
.bar:before {
    width: $bar-width;
    height: $bar-height;
    @media (max-width: 640px), (max-height: 460px) {
        width: 30px; } }

.bar {
    position: relative;
    transform: translateY($bar-spacing);
    background: $bar-color;
    transition: all 0ms 300ms;
    &.animate {
        background: rgba(255, 255, 255, 0); } }

.bar:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: $bar-spacing;
    background: $bar-color;
    transition: bottom $bar-speed $bar-speed ease, transform $bar-speed ease;
    @media (max-width: 575px), (max-height: 460px) {
        bottom: $bar-spacing-max575; } }

.bar:after {
    content: "";
    position: absolute;
    left: 0;
    top: $bar-spacing;
    background: $bar-color;
    transition: top $bar-speed $bar-speed ease, transform $bar-speed ease;
    @media (max-width: 575px), (max-height: 460px) {
        top: $bar-spacing-max575; } }

.bar.animate:after {
    top: 0;
    transform: rotate(45deg);
    transition: top $bar-speed ease, transform $bar-speed $bar-speed ease; }

.bar.animate:before {
    bottom: 0;
    transform: rotate(-45deg);
    transition: bottom $bar-speed ease, transform $bar-speed $bar-speed ease; }

.animate-menu {
    top: 0!important;
    bottom: 0!important;
    @media (max-width: 680px) {
        bottom: auto!important; } }
