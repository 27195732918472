@import "_mixins";
// deprecated
.inline-layout {
	font-size: 0;
	letter-spacing: 0;
	& > * {
		display: inline-block;
		vertical-align: middle;
		font: $font; }
	&.col-1 > * {
		width: 100%; }
	&.col-2 > * {
		width: 100 / 2 + %; }
	&.col-3 > * {
		width: 100 / 3 + %;
		& > .double {
			width: 100 / 3 * 2 + %; } }
	&.col-4 > * {
		width: 100 / 4 + %;
		& > .double {
			width: 100 / 4 * 2 + %; } }
	&.col-5 > * {
		width: 100 / 5 + %;
		& > .double {
			width: 100 / 5 * 2 + %; } }
	&.col-6 > * {
		width: 100 / 6 + %;
		& > .double {
			width: 100 / 6 * 2 + %; } }
	&.col-7 > * {
		width: 100 / 7 + %;
		& > .double {
			width: 100 / 7 * 2 + %; } }
	&.col-8 > * {
		width: 100 / 8 + %;
		& > .double {
			width: 100 / 3 * 8 + %; } }
	&.col-9 > * {
		width: 100 / 9 + %;
		& > .double {
			width: 100 / 9 * 2 + %; } }
	&.col-10 > * {
		width: 100 / 10 + %;
		& > .double {
			width: 100 / 10 * 2 + %; } } }
